/* eslint-disable import/no-extraneous-dependencies */
import { EMessageType } from "@lib/types/src/index";
import VideoMessage from "./video/VideoMessage";
import AudioMessage from "./audio/AudioMessage";
import ErrorMessage from "./error/ErrorText";
import ImageMessage from "./image/ImageMessage";
import PDFMessage from "./pdf/PDFMessage";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const messages: Record<EMessageType, React.FC<any>> = {
  [EMessageType.video]: VideoMessage,
  [EMessageType.audio]: AudioMessage,
  [EMessageType.error]: ErrorMessage,
  [EMessageType.image]: ImageMessage,
  [EMessageType.application]: PDFMessage,
};

function MessageFactory(props: TMessageAtachment) {
  const { type } = props;

  const typed = type as EMessageType;

  const Component = messages[typed];
  return <Component {...props} />;
}

export default MessageFactory;
