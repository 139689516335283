import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import axiosInstanceChat from '@/axios/axiosInstanceChat';
import { ProjectProps } from '@/types';

// eslint-disable-next-line import/prefer-default-export
export const useThemeQuery = (projectId: string | undefined) =>
  useQuery({
    queryFn: () => axios.get(`${import.meta.env.VITE_API_BASE_URL}/projects/get${projectId}/theme`),
    queryKey: ['chat_themes_branding', { projectId }],
    enabled: !!projectId,
    select: (data) => data?.data,
  });

export const useProjectData = (projectId: string | undefined, token: string | undefined) => {
  return useQuery({
    queryFn: () =>
      axiosInstanceChat.get(`projects/get${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    queryKey: ['single_project_data', { projectId }],
    select: (data) => data?.data,
    enabled: !!projectId,
  }) as { data: ProjectProps };
};
