export enum EMessageType {
  image = "image",
  video = "video",
  audio = "audio",
  error = "error",
  application = "application",
}

export enum EWorkspaceRoles {
  OWNER = "OWNER",
  EDITOR = "CAN_EDIT",
  VIEWER = "CAN_VIEW",
}

export enum EOrganizationRoles {
  OWNER = "OWNER",
  EDITOR = "EDITOR",
  ADMIN = "ADMIN",
}

export enum EProjectStatus {
  DRAFT = "DRAFT",
  READY = "READY",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  REQUEST_APPROVED = "REQUEST_APPROVED",
  PUBLISHED = "PUBLISHED",
  COMPLETED = "COMPLETED",
}
