import getTextColor from 'chat-ui/src/hooks/getTextColorContrast';
import { CreateThemeData } from '@/types';

function ThankYouScreenScreeningQuestions({
  themeData,
  screenedOutLink,
}: {
  themeData: CreateThemeData;
  screenedOutLink: string | null;
}) {
  return (
    <div
      style={{
        color: getTextColor(themeData?.['chat-background'] || '#F3F4F6'),
      }}
      className="relative flex flex-col items-center justify-between w-full h-full text-white pb-8"
    >
      <div className="w-full h-full flex flex-col max-w-[470px]">
        <div className="flex flex-col max-w-[360px]">
          <div
            className="text-sm text-gray-700 [&_a]:underlines [&_a]:text-blue-400 [&_a]:block [&_a]:cursor-pointer"
            dangerouslySetInnerHTML={{
              __html: screenedOutLink,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ThankYouScreenScreeningQuestions;
