import { useMutation } from '@tanstack/react-query';
import axiosInstanceChat from '@/axios/axiosInstanceChat';

export const useMutatePreescreenProcess = (
  chat_id: string | undefined,
  source: string | null,
  language: string | null,
) => {
  return useMutation({
    mutationFn: async ({
      question,
      answers,
      conversation_id,
    }: {
      question: string;
      answers: string[];
      conversation_id?: string;
    }) => {
      const queryParams = new URLSearchParams();
      if (conversation_id) queryParams.append('conversation_id', conversation_id);
      if (source) queryParams.append('source', source);
      if (language) queryParams.append('language', language);

      const response = await axiosInstanceChat.post(
        `/chat/${chat_id}/prescreen?${queryParams.toString()}`,
        {
          question,
          answers,
        },
      );
      return response.data;
    },
  });
};
