import React, { useEffect, useState } from 'react';
import { IconArrowRight, IconLoader2 } from '@tabler/icons-react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Image from 'chat-ui/src/components/Image/Image';
import { Button } from 'chat-ui/src/components/shadcn/button';
import getTextColor from 'chat-ui/src/hooks/getTextColorContrast';
import useTheme from 'chat-ui/src/hooks/useTheme';
import axios from 'axios';
import useChatSettingsStore from 'chat-ui/src/ChatSettingsStore';
import { ECompanyThemeName, ScreeningState, ScreeningType } from '@/enums/enums';
import TelletLogo from '@/assets/tellet-logo-main-white.svg';
import EmpathyLogo from '@/assets/empaty-logo.svg';
import { cn } from '@/lib/utils';
import { useMutatePreescreenProcess } from '@/reactQuery/post';
import { ScreeningChatQuestion, ScreeningQuestionsProps } from '@/types';
import ThankYouScreenScreeningQuestions from './ThankYouScreenScreeningQuestions';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: (
    <Image
      src={TelletLogo}
      alt="logo"
      height={50}
      width={50}
      className="absolute bottom-5 right-9"
    />
  ),
  [ECompanyThemeName.EMPATHY]: (
    <Image
      src={EmpathyLogo}
      alt="logo"
      height={120}
      width={120}
      className="absolute bottom-5 right-9"
    />
  ),
};

function ScreeningQuestionsChat({
  themeData,
  onScreenQuestionsEnd,
  screeningQuestions,
  projectStorageSuffix,
  projectId,
}: ScreeningQuestionsProps) {
  const conversationIdFromLocalStorage = localStorage.getItem(
    `current_conversation_id${projectStorageSuffix}`,
  );

  const { themeName } = useTheme();
  const { setChatSettingsData } = useChatSettingsStore();
  const currentIndex = +(
    localStorage.getItem(`screeningQuestionIndex${projectStorageSuffix}`) ?? '0'
  );
  const currentScreenedOutLink = localStorage.getItem(
    `screeningQuestionDisqualifyLink${projectStorageSuffix}`,
  );
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const [questionIndex, setQuestionIndex] = useState<number>(currentIndex ?? 0);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentScreeningState, setCurrentScreeningState] = useState<ScreeningState>(
    ScreeningState.ONGOING,
  );
  const [screenedOutLink, setScreenedOutLink] = useState<string | null>(
    currentScreenedOutLink || null,
  );
  const language = localStorage.getItem(`current_project_language_${projectStorageSuffix}`);
  const { chat_id } = useParams();
  const {
    mutateAsync,
    isPending,
    data: prescreenDTO,
  } = useMutatePreescreenProcess(chat_id, source, language);
  const currentQuestion = screeningQuestions?.[questionIndex] as ScreeningChatQuestion;
  const location = useLocation();
  const conversationID = conversationIdFromLocalStorage ?? prescreenDTO?.conversation_id;

  const saveChatSettings = (token: string, interviewType: string[]) => {
    const tokenKey = `current_project_token_${projectId}`;
    localStorage.setItem(tokenKey, token);
    localStorage.setItem(`chat_settings_${chat_id}`, interviewType.toString());
    localStorage.removeItem(`current_project_language_${projectStorageSuffix}`);
    localStorage.removeItem(`current_conversation_id${projectStorageSuffix}`);
    setChatSettingsData(interviewType);
    onScreenQuestionsEnd(true);
  };

  const getChat = async (conversationIdfromQualifyState: string) => {
    setIsLoading(true);
    try {
      const endpoint = `/chat/token${projectId}/${
        conversationID ?? conversationIdfromQualifyState
      }`;
      const res = await axios.get(
        `${import.meta.env.VITE_CHAT_URL}${endpoint}?language=${language}&${location.search.slice(
          1,
        )}`,
      );
      if (res?.data) {
        saveChatSettings(res?.data?.token, res?.data?.type_of_interview);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching chat:', error);
      setIsLoading(false);
    }
  };

  const handleNextQuestion = async () => {
    const currentPayload = {
      question: currentQuestion?.question,
      answers: selectedAnswers,
      conversation_id: conversationID,
    };
    try {
      const response = await mutateAsync(currentPayload);
      const { state, conversation_id } = response;
      setCurrentScreeningState(state);

      if (state === ScreeningState.ONGOING) {
        localStorage.setItem(`screeningState${projectStorageSuffix}`, ScreeningState.ONGOING);
        localStorage.setItem(
          `screeningQuestionIndex${projectStorageSuffix}`,
          (questionIndex + 1).toString(),
        );
        setQuestionIndex((prevQuestion) => prevQuestion + 1);
        setSelectedAnswers([]);
      }

      if (state === ScreeningState.QUALIFIED) {
        localStorage.setItem(`screeningState${projectStorageSuffix}`, ScreeningState.QUALIFIED);
        localStorage.removeItem(`screeningQuestionIndex${projectStorageSuffix}`);
        await getChat(conversation_id);
      }

      if (state === ScreeningState.DISQUALIFIED) {
        setScreenedOutLink(response?.link);
        localStorage.setItem(`screeningState${projectStorageSuffix}`, ScreeningState.DISQUALIFIED);
        localStorage.setItem(
          `screeningQuestionDisqualifyLink${projectStorageSuffix}`,
          response?.link,
        );
        localStorage.removeItem(`screeningQuestionIndex${projectStorageSuffix}`);
      }

      setSelectedAnswers([]);
    } catch (error) {
      console.error('Mutation error:', error);
    }
  };

  const handleSelectAnswer = (value: string, isMultiSelect: boolean) => {
    setSelectedAnswers((prev) => {
      if (isMultiSelect) {
        return prev.includes(value) ? prev.filter((answer) => answer !== value) : [...prev, value];
      }
      return [value];
    });
  };

  useEffect(() => {
    if (screeningQuestions?.length) {
      localStorage.setItem(`screeningState${projectStorageSuffix}`, ScreeningState.ONGOING);
    }
  }, []);

  useEffect(() => {
    if (prescreenDTO?.conversation_id) {
      localStorage.setItem(
        `current_conversation_id${projectStorageSuffix}`,
        prescreenDTO?.conversation_id,
      );
    }
  }, [prescreenDTO?.conversation_id]);

  const screeningQuestionsLength = screeningQuestions?.length || 0;
  const progressCount: number | undefined = questionIndex + 1;
  const progressLimit: number | undefined = screeningQuestionsLength;
  const percentage = progressCount && progressLimit ? (progressCount / progressLimit) * 100 : 0;
  const buttonText = questionIndex < screeningQuestionsLength - 1 ? 'Next' : 'Finish';
  const isButtonDisabled = selectedAnswers.length === 0 || isPending;
  const isDisqualified = currentScreeningState === ScreeningState.DISQUALIFIED;
  const thankYouScreenIsVisible = isDisqualified || screenedOutLink;
  const progressBarWidth = thankYouScreenIsVisible ? 0 : percentage ?? 0;

  return (
    <div
      style={{
        backgroundColor: themeData?.['chat-consent-bg'] || '#FF6F98',
        color: getTextColor(themeData?.['chat-consent-bg'] || '#FFFFFF'),
      }}
      className="relative flex flex-col items-center justify-center w-full h-full min-h-[100dvh] text-white"
    >
      <div className="w-full justify-between flex flex-col max-w-[400px] px-8 pb-8 pt-14 bg-white  shadow-lg rounded-lg">
        {/* progress bar */}
        <div
          className={cn('fixed left-0 right-0 overflow-hidden z-50 w-full h-6 top-0 bg-white/30')}
        >
          <div
            style={{
              width: `${progressBarWidth}%`,
              backgroundColor: themeData?.primary || '#096846',
            }}
            className={cn(
              'flex w-full justify-center items-center h-6 transition-all duration-700 ease-in-out rounded-r-full',
              percentage === 100 && 'rounded-r-none',
            )}
          />
        </div>

        {thankYouScreenIsVisible ? (
          <ThankYouScreenScreeningQuestions
            themeData={themeData}
            screenedOutLink={screenedOutLink}
          />
        ) : (
          <div>
            <div className="flex flex-col gap-2 mb-12 relative">
              <p className="font-medium text-sm text-dark-text absolute -top-10 -right-2 opacity-50">
                {`Question ${progressCount} / ${progressLimit}`}
              </p>
              <p className="font-bold text-lg text-dark-text max-w-[320px]">
                {currentQuestion?.question}
              </p>

              {/* questions */}
              <div className="flex flex-col gap-2 w-auto">
                {/* multiple select */}
                {currentQuestion?.type === ScreeningType.MULTIPLE_SELECT && (
                  <div className="flex mt-6 flex-col gap-3">
                    {React.Children.toArray(
                      currentQuestion?.responses?.map((item) => (
                        <label className="flex items-center gap-2.5 cursor-pointer w-fit text-dark-text text-gray-500 hover:text-dark-text transition-colors">
                          <input
                            onChange={() => handleSelectAnswer(item?.value, true)}
                            checked={selectedAnswers.some((answer) => answer === item?.value)}
                            type="checkbox"
                            className="min-w-5 min-h-5 self-start mt-0.5 cursor-pointer"
                            value={item?.value}
                          />
                          <p className="font-medium text-sm">{item?.value}</p>
                        </label>
                      )),
                    )}
                  </div>
                )}

                {/* single select */}
                {currentQuestion?.type === ScreeningType.SINGLE_SELECT && (
                  <div className="flex w-full mt-6 flex-col gap-3">
                    {React.Children.toArray(
                      currentQuestion?.responses?.map((item) => (
                        <label className="flex items-center gap-2.5 cursor-pointer w-fit text-dark-text text-gray-500 hover:text-dark-text transition-colors">
                          <input
                            onChange={() => handleSelectAnswer(item?.value, false)}
                            type="radio"
                            name={item?.value}
                            className="min-w-5 min-h-5 self-start mt-0.5 cursor-pointer"
                            value={item?.value}
                            checked={selectedAnswers.some((answer) => answer === item?.value)}
                          />
                          <p className="font-medium text-sm">{item?.value}</p>
                        </label>
                      )),
                    )}
                  </div>
                )}

                {/* numeric */}
                {currentQuestion?.type === ScreeningType.NUMERIC && (
                  <label className="flex flex-col gap-1 text-gray-500 hover:text-dark-text transition-colors mt-4">
                    <p className="text-xs">(write in numbers)</p>
                    <input
                      onChange={(e) => handleSelectAnswer(e.target.value, false)}
                      value={selectedAnswers[0] || ''}
                      type="number"
                      className="w-full text-dark-text shadow border rounded-md h-9 px-3 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    />
                  </label>
                )}
              </div>
            </div>
            <Button
              disabled={isButtonDisabled}
              onClick={handleNextQuestion}
              style={{
                backgroundColor: themeData?.primary,
                color: getTextColor(themeData?.primary),
              }}
              className="border-white border-2 self-end px-4 font-semibold flex items-center gap-2 ml-auto"
            >
              {buttonText}
              {isPending || isLoading ? (
                <IconLoader2 className="animate-spin" size={20} />
              ) : (
                <IconArrowRight size={20} />
              )}
            </Button>
          </div>
        )}
        {/* logo */}
        {themeName === 'default' && themeLogosList[themeName]}
      </div>
    </div>
  );
}

export default ScreeningQuestionsChat;
