import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IconArrowRight, IconLoader2 } from '@tabler/icons-react';
import axios, { AxiosError } from 'axios';
import Image from 'chat-ui/src/components/Image/Image';
import useTheme from 'chat-ui/src/hooks/useTheme';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';
import getTextColor from 'chat-ui/src/hooks/getTextColorContrast';
import useChatSettingsStore from 'chat-ui/src/ChatSettingsStore';
import { EChatErrors, ECompanyThemeName } from '@/enums/enums';
import TelletLogo from '@/assets/tellet-logo-main-white.svg';
import EmpathyLogo from '@/assets/empaty-logo.svg';
import languagesList from '../languageList';
import SelectForm from '../SelectForm';
import {
  translateWelcome,
  translatePickLanguage,
  translateLetsGo,
  translateSurvey,
  translations,
  translateAIInterview,
} from '../translatedConsentText';
import { Checkbox } from '../ui/checkbox';
import languagesListNormalLabels from '../languageListNormalLabels';
import { ConsentScreenProps } from '@/types';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: (
    <Image
      src={TelletLogo}
      alt="logo"
      height={50}
      width={50}
      className="absolute bottom-5 right-6"
    />
  ),
  [ECompanyThemeName.EMPATHY]: (
    <Image
      src={EmpathyLogo}
      alt="logo"
      height={120}
      width={120}
      className="absolute bottom-5 right-6"
    />
  ),
};

type LanguageCode = keyof typeof translations;

function ConsentScreen({
  projectId,
  setProjectConsent,
  themeData,
  refetch,
  interviewSource,
  projectStorageSuffix,
}: ConsentScreenProps) {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({ label: '', value: '' });
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState('');
  const [languageFromDashboard, setLanguageFromDashboard] = useState<string>('');
  const { chat_id } = useParams();
  const navigate = useNavigate();
  const [showLanguage, setShowLanguage] = useState(false);
  const location = useLocation();
  const [browserLanguage, setBrowserLanguage] = useState('');
  const [searchParams] = useSearchParams();
  const isSandboxChat = searchParams.get('environment');
  const sandboxLink = isSandboxChat === 'sandbox';
  const { themeName } = useTheme();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { setChatSettingsData } = useChatSettingsStore();
  const activeLanguage = showLanguage
    ? (browserLanguage as LanguageCode)
    : (languageFromDashboard as LanguageCode);
  const translation = translations[activeLanguage] || translations.en;

  const interviewTermsLink =
    themeName === ECompanyThemeName.EMPATHY
      ? 'https://ai-terms.empathyiq.ai/'
      : translation.interviewTermsLink;

  const handleSubmit = () => {
    localStorage.setItem(
      `project_consent_${projectId}${sandboxLink ? '/sandbox' : ''}${interviewSource || ''}`,
      'true',
    );
    setProjectConsent(true);
  };

  const sanitizedFirstParagraph = DOMPurify.sanitize(
    translation.firstParagraph
      .replace(
        '{interviewTerms}',
        `<a href="${interviewTermsLink}" target="_blank" class="font-medium underline">${translation.interviewTerms}</a>`,
      )
      .replace(
        '{privacyPolicy}',
        `<a href="${privacyPolicyLink}" target="_blank" class="font-medium underline">${translation.privacyPolicy}</a>`,
      ),
  );

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/projects/consent_screen/${chat_id}`,
      );
      const { data } = res;
      const languageItem = languagesList?.language.filter((item) => item?.value === data?.language);
      Cookies.set('current_organization_chat', data?.organization_id);
      setPrivacyPolicyLink(data?.privacy_policy);
      setShowLanguage(data?.allow_language_selection);
      setSelectedLanguage(languageItem[0]);

      const formattedDashboardLanguage = languagesListNormalLabels?.language?.find(
        (lang) => lang?.label === data?.language,
      )?.value;
      setLanguageFromDashboard(formattedDashboardLanguage as string);
    } catch (err) {
      setPrivacyPolicyLink('');
    }
  };

  const saveChatSettings = (
    chatId: string | undefined,
    token: string,
    interviewType: string[],
    isSandbox: boolean,
  ) => {
    const tokenKey = `current_project_token_/${chatId}${isSandbox ? '/sandbox' : ''}`;
    localStorage.setItem(tokenKey, token);
    setChatSettingsData(interviewType);
    localStorage.setItem(`chat_settings_${chatId}`, interviewType.toString());
  };

  const getChat = async (sandbox = false) => {
    try {
      if (!sandbox) {
        const { data } = await refetch();
        if (data && !!data?.length) {
          setIsSubmitLoading(false);
          localStorage.setItem(
            `current_project_language_${projectStorageSuffix}`,
            selectedLanguage?.value,
          );
          handleSubmit();
          return;
        }
      }
      const endpoint = sandboxLink ? `/chat/sandbox/token/${chat_id}` : `/chat/token/${chat_id}`;

      const res = await axios.get(
        `${
          import.meta.env.VITE_CHAT_URL
        }${endpoint}?language=${selectedLanguage?.value}&${location.search.slice(1)}`,
      );

      if (res?.data) {
        if (sandboxLink) {
          localStorage.setItem(
            `current_conversation_id/sandbox/${projectId}`,
            res.data.conversationId,
          );
        }

        saveChatSettings(chat_id, res.data.token, res.data.type_of_interview, sandboxLink);
      }

      setIsSubmitLoading(false);
      handleSubmit();
    } catch (error) {
      console.error('Error fetching chat:', error);
      setIsSubmitLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    setIsSubmitLoading(true);

    try {
      getChat(sandboxLink);
    } catch (err) {
      const axiosError = err as AxiosError;
      const chatError = axiosError.response?.status;
      const errorStatusMap: Record<number, EChatErrors> = {
        [EChatErrors.PROJECT_DO_NOT_EXIST]: EChatErrors.PROJECT_DO_NOT_EXIST,
        [EChatErrors.PROJECT_IS_DRAFT_OR_READY]: EChatErrors.PROJECT_IS_DRAFT_OR_READY,
        [EChatErrors.PROJECT_IS_COMPLETED]: EChatErrors.PROJECT_IS_COMPLETED,
        [EChatErrors.MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED]:
          EChatErrors.MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED,
      };

      const errorStatus = chatError && errorStatusMap[chatError];
      if (errorStatus) {
        return navigate(`/chat/error?error_status=${errorStatus}`);
      }
      setIsSubmitLoading(false);
      navigate('/chat/error-page');
    }
  };

  useEffect(() => {
    const { language } = navigator;
    const formattedLanguage = new Intl.Locale(language).language;
    setBrowserLanguage(formattedLanguage);
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSandboxChat !== null && isSandboxChat !== 'sandbox') {
      navigate('/chat/error-page');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSandboxChat]);

  const isButtonDisabled = !isTermsChecked || isSubmitLoading;

  return (
    <div
      style={{
        backgroundColor: themeData?.['chat-consent-bg'] || '#FF6F98',
        color: getTextColor(themeData?.['chat-consent-bg'] || '#FFFFFF'),
      }}
      className="flex items-center justify-center"
    >
      {/* logo */}
      {themeData?.name === 'Default' && themeLogosList[themeName]}
      <div className="relative flex max-w-[480px] flex-col items-center justify-between w-full h-full min-h-[100dvh] px-8 pt-12">
        <div className="flex flex-col w-full h-full">
          <h2 className="mb-6 text-4xl font-bold">
            {translateWelcome[activeLanguage] || translateWelcome.en}!
          </h2>

          <p className="mb-3 text-base font-light">
            {translateSurvey[activeLanguage] || translateSurvey.en}
          </p>

          <p className="mb-3 text-base font-light">
            {translateAIInterview[activeLanguage] || translateAIInterview.en}
          </p>

          {/* choose language */}
          {showLanguage && selectedLanguage?.value !== '' && (
            <div className="relative flex flex-col w-full gap-5 mt-10">
              <p className="text-base font-light">
                {translatePickLanguage[activeLanguage] || translatePickLanguage.en}
              </p>
              <SelectForm
                selectValue={selectedLanguage}
                selectOnChange={setSelectedLanguage}
                selectList={languagesList.language}
              />
            </div>
          )}
        </div>

        <div className="flex font-semibold self-end items-center flex-col w-full h-full max-w-[250px]">
          <div className="flex items-center self-end mb-8">
            {/* eslint-disable-next-line react/no-danger */}
            <p
              className="mb-3 text-base font-light"
              dangerouslySetInnerHTML={{ __html: sanitizedFirstParagraph }}
            />
            <Checkbox
              onClick={() => setIsTermsChecked(!isTermsChecked)}
              checked={isTermsChecked}
              style={{ borderColor: getTextColor(themeData?.['chat-consent-bg']) || '#FFFFFF' }}
              className="w-9 h-9 border-2 mt-2 border-white rounded-xl data-[state=checked]:bg-white data-[state=checked]:text-chat-primary"
            />
          </div>
          <button
            type="button"
            onClick={onSubmit}
            disabled={isButtonDisabled}
            style={{
              backgroundColor: themeData?.primary,
              opacity: isTermsChecked ? 1 : 0.7,
              color: getTextColor(themeData?.primary),
            }}
            className="flex items-center self-end disabled:!opacity-70 justify-center h-10 gap-1 px-3 mb-20 border-2 border-white w-fit rounded-xl"
          >
            {isSubmitLoading && <IconLoader2 size={20} className="animate-spin" />}
            {translateLetsGo[activeLanguage] || translateLetsGo.en}
            <IconArrowRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConsentScreen;
