import axios from 'axios';
import * as Sentry from '@sentry/react';

const ERROR_STATUS = {
  METADATA_MISSING: 434,
  TEST_CONVERSATION_NOT_FOUND: 426,
};

const path = window.location.pathname;
const pathParts = path.split('/');
const url_project_id = pathParts[1];

const axiosInstanceChat = axios.create({
  baseURL: import.meta.env.VITE_CHAT_URL,
});

axiosInstanceChat.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);

    // metadata missing
    if (error?.response?.status === ERROR_STATUS.METADATA_MISSING) {
      window.location.href = '/chat/error-page';
    }

    // conversation missing
    if (error?.response?.status === ERROR_STATUS.TEST_CONVERSATION_NOT_FOUND) {
      localStorage.removeItem(`current_project_token_/${url_project_id}/sandbox`);
      localStorage.removeItem(`project_consent_/${url_project_id}/sandbox`);
      localStorage.removeItem(`current_conversation_id/sandbox//${url_project_id}`);
      window.location.reload();
    }

    return Promise.reject(error.response);
  },
);

export const chatRegister = async (
  projectId: string | undefined,
  allSearchParams: string | undefined,
): Promise<{ token: string }> => {
  if (!projectId) {
    throw new Error('Project ID is not defined');
  }
  const { data } = await axiosInstanceChat.get(`/chat/token${projectId}${allSearchParams || ''}`);
  return data;
};

export const getChatHistory = async (token: string | undefined): Promise<TMessageData[]> => {
  const reponse = await axiosInstanceChat.get('/chat/history', {
    headers: { Authorization: `Bearer ${token}` },
  });

  return reponse.data;
};

export default axiosInstanceChat;
