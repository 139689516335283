// eslint-disable-next-line import/prefer-default-export
export enum ECompanyThemeName {
  DEFAULT = 'default',
  EMPATHY = 'empathyiq',
}

export enum EChatErrors {
  PROJECT_IS_COMPLETED = 442,
  PROJECT_IS_DRAFT_OR_READY = 425,
  PROJECT_DO_NOT_EXIST = 404,
  MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED = 433,
  CHAT_EXPIRED = 445,
  METADATA_REQUIRED = 434,
  INVALID_LANGUAGE = 435,
  TEST_CONVERSATION_NOT_FOUND = 426,
}

export enum ScreeningType {
  SINGLE_SELECT = 'singleSelect',
  MULTIPLE_SELECT = 'multiSelect',
  NUMERIC = 'numericInput',
}

export enum ScreeningState {
  DISQUALIFIED = 'disqualified',
  ONGOING = 'ongoing',
  QUALIFIED = 'qualified',
}
