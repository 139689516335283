import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import ChatPresentation from 'chat-ui/ChatPresentation';
import useChatStore from 'chat-ui/src/store';
import axiosInstanceChat, { getChatHistory } from '@/axios/axiosInstanceChat';
import FullPageLoader from '../FullPageLoader';
import { EChatErrors } from '@/enums/enums';
import { useThemeQuery } from '@/reactQuery/get';
import ScreeningQuestionsChat from './ScreeningQuestionsChat';
import { ChatProps } from '@/types';

// const chatCannotRegisterError = `We cannot find this chat.
// Please check the link or contact the owner of the chat.`;
const errorSendingMessage = "We can't send your message. Please try again.";

function Chat({
  projectId,
  chatHeight,
  setProjectConsent,
  screeningQuestions,
  projectStorageSuffix,
}: ChatProps) {
  const {
    addErrorMessage,
    setInitialMessages,
    setCurrentProjectToken,
    currentProjectToken,
    setSeen,
    setRefreshChatHistory,
    setNoSpeechDetected,
    noSpeechDetected,
  } = useChatStore((state) => state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const isSandboxChat = searchParams.get('environment');
  const sandboxLink = isSandboxChat === 'sandbox';
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [areScreeningQuestionsEnded, setAreScreeningQuestionsEnded] = useState(false);
  const { data: themeData } = useThemeQuery(projectId);

  const hasQualifiedForInterview =
    localStorage.getItem(`screeningState${projectStorageSuffix}`) === 'qualified';
  const showScreeningQuestions =
    screeningQuestions &&
    screeningQuestions?.length > 0 &&
    !areScreeningQuestionsEnded &&
    !hasQualifiedForInterview &&
    !sandboxLink;
  // eslint-disable-next-line consistent-return
  const refreshChatHistory = useCallback(async () => {
    try {
      const data = await getChatHistory(currentProjectToken);
      setInitialMessages(data);
      setSeen();
    } catch (err) {
      const axiosError = err as AxiosError;
      const chatError = axiosError?.response?.status;
      if (chatError === EChatErrors.CHAT_EXPIRED) {
        return navigate(`/chat/error?error_status=${EChatErrors.CHAT_EXPIRED}`);
      }
      return addErrorMessage(errorSendingMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectToken]);

  useEffect(() => {
    setRefreshChatHistory(() => refreshChatHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectToken]);

  const handleChatRegister = useCallback(
    async () => {
      const tokenFromStorage = localStorage.getItem(`current_project_token_${projectId}`) as string;
      const tokenFromStorageSandbox = localStorage.getItem(
        `current_project_token_${projectId}/sandbox`,
      ) as string;

      setLoading(true);
      try {
        const token = sandboxLink ? tokenFromStorageSandbox : tokenFromStorage;
        setCurrentProjectToken(token);
      } catch (error) {
        console.error('Error setting project token:', error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, areScreeningQuestionsEnded],
  );

  useEffect(() => {
    if (currentProjectToken) {
      getChatHistory(currentProjectToken)
        .then((data) => {
          setInitialMessages(data);
          setSeen();
        })
        .catch((error) => {
          const axiosError = error as AxiosError;
          localStorage.removeItem(`current_project_token_${projectId}${sandboxLink && '/sandbox'}`);
          localStorage.removeItem(`project_consent_${projectId}${sandboxLink ? '/sandbox' : ''}`);
          if (axiosError && axiosError.response?.status !== 401) {
            addErrorMessage(errorSendingMessage);
            localStorage.clear();
            setProjectConsent(false);
            window.location.reload();
          }
        });
    }
  }, [currentProjectToken]);

  const handleRestartChat = useCallback(async () => {
    setIsRefreshLoading(true);
    try {
      const currentConversationId = localStorage.getItem(
        `current_conversation_id/sandbox/${projectId}`,
      );
      const response = await axiosInstanceChat.post(
        `${import.meta.env.VITE_CHAT_URL}/chat/sandbox/reset${projectId}/${currentConversationId}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${currentProjectToken}`,
          },
        },
      );

      setProjectConsent(false);
      localStorage.removeItem(`current_project_token_${projectId}${sandboxLink && '/sandbox'}`);
      localStorage.removeItem(`project_consent_${projectId}${sandboxLink ? '/sandbox' : ''}`);
      localStorage.removeItem(`chat_settings_${projectId?.slice(1)}`);
      localStorage.removeItem(`current_conversation_id/sandbox/${projectId}`);
      setIsRefreshLoading(false);
      setInitialMessages(response.data.messages);
    } catch {
      setIsRefreshLoading(false);
      addErrorMessage(errorSendingMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, currentProjectToken]);

  useEffect(() => {
    const currentToken = localStorage.getItem(
      `current_project_token_${projectId}${sandboxLink && '/sandbox'}`,
    );
    if (areScreeningQuestionsEnded) {
      handleChatRegister();
      return;
    }
    if (currentToken) {
      setCurrentProjectToken(currentToken);
      setLoading(false);
    } else if (!currentToken) {
      handleChatRegister();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, areScreeningQuestionsEnded]);

  useEffect(() => {
    if (isSandboxChat !== null && isSandboxChat !== 'sandbox') {
      navigate('/chat/error-page');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSandboxChat]);

  useEffect(() => {
    if (noSpeechDetected) {
      const fetchHistory = async () => {
        await refreshChatHistory();
        setNoSpeechDetected(false);
      };

      fetchHistory();
    }
  }, [refreshChatHistory, noSpeechDetected, setNoSpeechDetected]);

  if (loading) return <FullPageLoader />;

  if (showScreeningQuestions) {
    return (
      <ScreeningQuestionsChat
        themeData={themeData}
        screeningQuestions={screeningQuestions}
        onScreenQuestionsEnd={setAreScreeningQuestionsEnded}
        projectStorageSuffix={projectStorageSuffix}
        setProjectConsent={setProjectConsent}
        projectId={projectId}
      />
    );
  }

  return (
    <ChatPresentation
      chatHeight={chatHeight}
      isTestChat={false}
      handleRestartChat={handleRestartChat}
      isRefreshLoading={isRefreshLoading}
      themeData={themeData}
      areScreeningQuestionsEnded={areScreeningQuestionsEnded}
    />
  );
}

export default Chat;
