import { PDFMessageProps } from "../../../types";
import PDFIcon from "../../icons/PDFIcon";

function PDFMessage({ url, file_id }: PDFMessageProps) {
  const trimmedName = file_id?.split("_").pop();
  const name = trimmedName?.split(".")[0] + ".pdf";
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="flex w-fit m-auto gap-2 items-center bg-white p-2 px-4 rounded-lg shadow-md hover:bg-gray-100 transition-colors duration-200"
    >
      <PDFIcon />
      {name}
    </a>
  );
}

export default PDFMessage;
