import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import useChatSettingsStore from 'chat-ui/src/ChatSettingsStore';
import Chat from '@/components/chat/Chat';
import ConsentScreen from '@/components/chat/ConsentScreen';
import FullPageLoader from '@/components/FullPageLoader';
import { useThemeQuery } from '@/reactQuery/get';
import axiosInstanceChat from '@/axios/axiosInstanceChat';

function ChatPage() {
  const projectId = window.location.pathname;
  if (projectId) projectId.replace('/', '');
  const [searchParams] = useSearchParams();
  const isSandboxChat = searchParams.get('environment');
  const sandboxLink = isSandboxChat === 'sandbox';
  const interviewSource = searchParams.get('source');
  const [projectConsent, setProjectConsent] = useState(false);
  const { setChatSettingsData } = useChatSettingsStore();
  const { data: themeData, isLoading: isThemeLoading } = useThemeQuery(projectId);
  const interviewSourceSuffix = interviewSource ? `_${interviewSource}` : '';
  const projectStorageSuffix = `_${projectId}${interviewSourceSuffix}`;
  const areScreeningQuestionsOngoing = localStorage.getItem(
    `screeningState${projectStorageSuffix}`,
  );

  const { data: screeningQuestions, refetch } = useQuery({
    queryFn: () => axiosInstanceChat.get(`/chat${projectId}/prescreen`),
    queryKey: ['prescreen', { projectId }],
    enabled:
      !!projectId &&
      !sandboxLink &&
      (areScreeningQuestionsOngoing === 'ongoing' ||
        areScreeningQuestionsOngoing === 'disqualified'),
    select: (data) => data?.data,
    staleTime: 1000 * 30,
  });

  useEffect(() => {
    const localStorageConsent = localStorage.getItem(
      `project_consent_${projectId}${sandboxLink ? '/sandbox' : ''}${
        interviewSource ? `${interviewSource}` : ''
      }`,
    );
    const chatSettings = localStorage.getItem(`chat_settings_${projectId.slice(1)}`);
    const chatSettingsFormatted = chatSettings?.split(',');
    setChatSettingsData(chatSettingsFormatted as string[]);

    setProjectConsent(localStorageConsent === 'true');
  }, [projectId, sandboxLink, setChatSettingsData]);

  if (isThemeLoading) return <FullPageLoader themeData={themeData} />;

  if (!projectConsent) {
    return (
      <ConsentScreen
        projectId={projectId}
        setProjectConsent={setProjectConsent}
        themeData={themeData}
        screeningQuestions={screeningQuestions}
        refetch={refetch}
        interviewSource={interviewSource}
        projectStorageSuffix={projectStorageSuffix}
      />
    );
  }

  return (
    <div className="min450:bg-white chat-page">
      <div
        style={{ backgroundColor: themeData?.['chat-background'] || '#F3F4F6' }}
        className="fixed top-0 bottom-0 left-0 right-0 w-full h-full min500:!bg-white"
      ></div>
      <Chat
        chatHeight="h-[calc(100dvh-95px)] scrollbar-hidden min500:h-[calc(100dvh-85px)]"
        projectId={projectId}
        setProjectConsent={setProjectConsent}
        screeningQuestions={screeningQuestions}
        projectStorageSuffix={projectStorageSuffix}
      />
    </div>
  );
}

export default ChatPage;
